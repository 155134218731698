import React from "react";
import styled from "styled-components";
import Card from "../Card/Card";
import ss from "../../images/Marketing.svg";
import Marketing from "../../images/Marketing.svg";
import Operation from "../../images/Operation.svg";
import HpCreate from "../../images/hp_create.svg";
import Design from "../../images/design.svg";
import ECSite from "../../images/ec_site.svg";
import SEO from "../../images/seo.svg";

const CardListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: 0 auto;
`;

const StyledImage = styled.img`
  width: 70px;
  height: 70px;
`;

const data = [
  {
    title: "Web制作",
    url: "/hp-create",
    comp: <StyledImage alt={"HP制作"} src={HpCreate} />,
    description:
      "業種・業界の様々なビジネスモデルのWebページ(ホームページ、Webデザイン、システム開発)制作を中心として新規顧客を獲得する為試行錯誤を重ねながら売り上げ向上を目標にサポートします。",
  },
  {
    title: "デザイン制作",
    url: "/design-create",
    comp: <StyledImage alt={"デザイン制作"} src={Design} />,
    description:
      "Webデザイン、アプリケーションデザイン、メニューデザインなどデザインの違いで読みやすさ、伝わり方が変わります。シーンに合わせた見せ方をお客様のご相談・ご要望に合わせながら提供します。",
  },
  {
    title: "ECサイト構築・運用",
    url: "/ec-site",
    comp: <StyledImage alt={"ECサイト構築・運用"} src={ECSite} />,
    description:
      "Shopifyの利用をはじめ、楽天市場、Yahoo!ショッピングなどの方法があります。集客、運用までサポートします。",
  },
  {
    title: "SEO対策",
    url: "/seo",
    comp: <StyledImage alt={"SEO対策"} src={SEO} />,
    description:
      "キーワードの選定、競合他社の調査、コンテンツの企画、制作を中心としたテクニカルな手法でコンテンツ主導の本質的なSEO対策を実施します。",
  },
  {
    title: "マーケティング",
    url: "/marketing",
    comp: <StyledImage alt={"マーケティング"} src={Marketing} />,
    description:
      "戦略立案、コンテンツマーケティング、SNSマーケティングなど、Webマーケティング施策をワンストップでご支援します。",
  },
  {
    title: "運用・保守",
    url: "/operation",
    comp: <StyledImage alt={"運用・保守"} src={Operation} />,
    description:
      "情報の更新、画像の差し替え、サーバーダウン時の対応などWebページを安全に保持する",
  },
];

const CardList = () => {
  return (
    <CardListWrap>
      {data.map((d) => {
        return (
          <Card
            key={d.title}
            title={d.title}
            url={d.url}
            comp={d.comp}
            description={d.description}
          />
        );
      })}
    </CardListWrap>
  );
};

export default CardList;

const size = {
  mobile: "375px",
  tablet: "767px",
  desktop: "1080px"
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`
};

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { device } from "./../Helper/device";
import Navbar from "./Navbar/Navbar";
import HeaderBottomBox from "./header-bottom-box";
import Phone from "./../images/phone.svg";
const Wrap = styled.header`
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 10;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  @media ${device.tablet} {
    position: relative;
  }
  @media ${device.desktop} {
    height: 100px;
  }
`;
const HeaderItemWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const HeaderLink = styled(Link)`
  display: block;
  margin-left: 20px;
  color: #333333;
  font-size: 24px;
  text-decoration: none;
  @media ${device.desktop} {
    font-size: 30px;
  }
`;
const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  @media ${device.tablet} {
    display: none;
  }
`;
const PhoneAnchor = styled.a`
  position: absolute;
  right: 90px;
`;

const Header = () => {
  return (
    <>
      <Wrap>
        <HeaderItemWrap>
          <HeaderLink to={"/"}>株式会社八坂堂</HeaderLink>
          <PhoneAnchor href="tel:+819095757070">
            <StyledImage src={Phone} />
          </PhoneAnchor>
          <Navbar />
        </HeaderItemWrap>
      </Wrap>
      <HeaderBottomBox />
    </>
  );
};

export default Header;

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { device } from "../../Helper/device";
const NavItem = styled(Link)`
  padding: 20px 0;
  font-size: 1.5rem;
  z-index: 6;
  text-decoration: none;
  color: #333333;
  @media ${device.tablet} {
    height: 25px;
    font-size: 16px;
    padding: 0 15px 5px;
    text-align: center;
    color: #333333;
    background-color: initial;
    display: inline-block;
    white-space: nowrap;
    transition: all 200ms ease-in;
    position: relative;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/">トップページ</NavItem>
      <NavItem to="/service-list">サービス一覧</NavItem>
      <NavItem to="/company-overview">会社概要</NavItem>
      <NavItem to="/contact">お問い合わせ</NavItem>
    </>
  );
};

export default NavbarLinks;

import React, { useState } from "react";
import styled from "styled-components";
import NavbarLinks from "./NavbarLinks";
import { device } from "../../Helper/device";
const Navigation = styled.nav`
  position: absolute;
  right: 20px;
  @media ${device.tablet} {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    margin-left: auto;
    z-index: 2;
    align-self: center;
  }
`;

const Toggle = styled.div`
  display: flex;

  @media ${device.tablet} {
    display: none;
    height: 100%;
    cursor: pointer;
    padding: 0 10vw;
  }
`;

const Navbox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  justify-content: flex-start;
  padding-top: 10vh;
  top: 80px;
  background-color: #ffffff;
  left: ${props => (props.open ? "-100%" : "0")};
  z-index: 100;
  height: 100%;
  @media ${device.tablet} {
    display: block;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    background: none;
    position: initial;
    padding-top: 0;
    top: 0;
    left: 0;
    transition: initial;
    flex-direction: initial;
  }
`;

const Hamburger = styled.div`
  background-color: #333333;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #333333;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`;
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Navigation>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )}
    </Navigation>
  );
};

export default Navbar;

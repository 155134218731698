import React from "react";
import styled from "styled-components";
import { device } from "./../Helper/device";
// styles

const Wrap = styled.div`
  height: 80px;
  width: 100%;
  @media ${device.tablet} {
    display: none;
  }
`;

const HeaderBottomBox = () => {
  return <Wrap />;
};

export default HeaderBottomBox;

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { device } from "../../Helper/device";

const Container = styled.li`
  display: block;
  width: 300px;
  height: 450px;
  text-decoration: none;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 8%);
  transition: all 300ms 0s ease;
  margin: 0 auto 40px;
  padding: 0 40px;
  color: #08121a;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 64px auto 40px;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`;

const StyledLink = styled(Link)`
  display: block;
  width: 300px;
  height: 450px;
  text-decoration: none;
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 8%);
  transition: all 300ms 0s ease;
  margin: 0 auto 40px;
  padding: 0 40px;
  color: #08121a;
`;

const Title = styled.h2`
  display: block;
  text-align: center;
  color: #08121a;
  font-weight: bold;
  margin: 20px 0;
  margin-block: 0;
  margin-inline: 0;
`;

const Card = ({ title, url, comp, description }) => {
  return (
    <Container to={url}>
      <ImageContainer>{comp}</ImageContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default Card;

import React from "react";
import styled, { keyframes } from "styled-components";
import { device } from "./../Helper/device";

import Header from "../components/header";
import CardList from "../components/Card/CardList";

const MainWrapFadeIn = keyframes`
  0% {width: 0px;}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MainWrap = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  margin: 0 0 50px 0;
  background-color: #000000;
  animation: ${MainWrapFadeIn} 1.5s ease;
  display: flex;
  justify-content: start;
  align-items: center;
  @media ${device.desktop} {
    width: 100%;
  }

  :after {
    content: "";
    height: 0;
    display: block;
    position: absolute;
    right: 0;
    border-top: solid 250px #fff;
    border-left: solid 250px transparent;
    top: -3px;
    z-index: 2;
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  height: 100%;
  color: #ffffff;
  animation: ${fadeIn} 1.5s ease;
`;

const MainLabel = styled.h2`
  display: block;
  font-size: 40px;
  padding: 20px;
`;
const SubLabel = styled.p`
  display: block;
  font-size: 20px;
  padding: 20px;
`;

const ServiceList = () => {
  return (
    <div>
      <Header />
      <MainWrap>
        <TitleWrap>
          <MainLabel>SERVICE</MainLabel>
          <SubLabel>サービス一覧</SubLabel>
        </TitleWrap>
      </MainWrap>
      <CardList />
    </div>
  );
};

export default ServiceList;
